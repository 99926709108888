import React, { useEffect }from "react";
import "../Contact/Contact.scss";
// import gsap, { TimelineMax, Elastic, Linear } from "gsap/all";
// import gsap from "gsap/all";
// import { ScrollTrigger } from "gsap/ScrollTrigger";
// import { Link } from "react-router-dom";


const Contact = () => {

    return (
        <div className={"contact"}>
         <h4>Email: <a href="mailto:mike@meggaventures.io">mike@meggaventures.io</a></h4>
         <h4>Address: 20030 Bluff Point Drive Millsboro DE 19966</h4>
        </div>
    );
   
}

export default Contact;