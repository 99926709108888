import React, { useEffect }from "react";
import "../Home/Home.scss";
// import gsap, { TimelineMax, Elastic, Linear } from "gsap/all";
// import gsap from "gsap/all";
// import { ScrollTrigger } from "gsap/ScrollTrigger";
// import { Link } from "react-router-dom";


const Home = () => {

    return (
        <div className={"home"}>
         <div className={"logo-cont"}>
          <img src="../../../../img/megga-ventures-logo.png" alt="megga-ventures-logo"></img>
         </div>
         <div className={"animation-cont"}>
          <div className={"headline-cont"}>
           <h3>Software Engineering</h3>
           <h3>Blockchain Engineering</h3>
           <h3>App Development</h3>
          </div>
         </div>
        </div>
    );
   
}

export default Home;